<template>

    <div class="w-3/5 p-4 pr-8 flex flex-col">

        <div class="flex items-center mb-2 h-12">
            <div class="module-title self-center">SMU</div>
            <div class="flex ml-auto">
                <div class="ml-2">
                    <div @click="toggleSlSelectedItemsAll()" class="pill-gray h-full flex flex-col justify-center items-center">
                        <span :class="{'text-aux':all}">ALL</span>
                    </div>
                </div>
                <div class="ml-2" v-for="(pill, k) in legendColors">
                    <div @click="toggleSlSelectedItems(k)" class="pill-gray"
                         :style="getOpacity(pill, k)">
                        {{ pill.name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="h-full overflow-hidden relative">
            <div class="absolute bg-box-80 z-1flex items-center mb-40 h-full w-full
                top-0 rounded-lg left-0" v-if="loading">
                <loader :loading="loading" />
            </div>
            <div v-else-if="!loading && smu && smu.length === 0" class="h-full w-full flex flex-col justify-center items-center">
                <span class="module-title text-font-gray">No data in this section</span>
            </div>
            <div class="scroll-gradient"></div>
            <transition-group name="fade" tag="div" class="h-full overflow-y-auto pb-10">
                <div class="box mb-2 flex" :key="el.id + '' + removeCharacters(el.id_sl) + '' + removeCharacters(el.name)"
                    v-for="el in smuFiltered">
                    <div class="w-1 rounded mr-2"
                         :style="'background-color:' + legendColors[el.id_sl].color + ';'">
                    </div>
                    <div class=" flex flex-col w-full overflow-hidden">
                        <div class="text-xl font-bold mb-4">{{ el.name }}</div>
                        <div class="flex items-center">

                            <div class="w-full flex flex-row justify-start items-center">

                                <span class="text-font-gray font-semibold mr-2">ANSR</span>
                                <span class="text-lg font-semibold mr-auto">{{ el.ner | reduceBigNumbers(2) }}€</span>

                                <span class="text-font-gray font-semibold mr-2">vs Obj.</span>
                                <span class="text-lg font-semibold mr-auto" :class="{'text-success': el.ner_vs_objective > 0, 'text-danger': el.ner_vs_objective <= 0}"><span v-if="el.ner_vs_objective > 0">+</span>{{ el.ner_vs_objective | reduceBigNumbers(2) }}<span v-if="el.ner_vs_objective!='TBD'">%</span></span>

                                <span v-if="el.TNR != 'N/A'" class="text-font-gray font-semibold mr-2">TNR</span>
                                <span v-if="el.TNR != 'N/A'" class="text-lg font-semibold" :class="{'text-success': el.TNR > 0, 'text-danger': el.TNR <= 0}">{{ el.TNR | reduceBigNumbers(2) }}€</span>

                                <span class="text-font-gray font-semibold ml-auto mr-2">Rate</span>
                                <span class="text-lg font-semibold mr-auto" :class="{'text-success': el.average_rate > 0, 'text-danger': el.average_rate <= 0}">{{ el.average_rate | reduceBigNumbers(2) }}€/h</span>

                                <span class="text-font-gray font-semibold mr-2">Unbilled</span>
                                <span class="text-lg font-semibold mr-auto" :class="{'text-success': el.unbilled >= 0, 'text-danger':el.unbilled < 0}"> {{ el.unbilled | reduceBigNumbers(2) }}€</span>

                                <span class="text-font-gray font-semibold mr-2">PMC</span>
                                <span class="text-lg font-semibold mr-auto" :class="{'text-success': el.pm_payment >= 0, 'text-danger':el.pm_payment < 0}">{{ el.pm_payment | reduceBigNumbers(0) }}d</span>
                                
                                <span class="text-font-gray font-semibold mr-2">Margin</span>
                                <span class="text-lg font-semibold mr-auto" :class="{'text-success': el.margin >= 0, 'text-danger': el.margin < 0}">{{ el.margin | reduceBigNumbers(2) }}%</span>
                                
                                <span class="text-font-gray font-semibold mr-2">vs Obj</span>
                                <span class="text-lg font-semibold" :class="{'text-success': el.avg_sale >= 0, 'text-danger':el.avg_sale < 0}"><span v-if="el.margin_vs_objective>0">+</span>{{ el.margin_vs_objective | reduceBigNumbers(2) }}<span v-if="el.margin_vs_objective!='TBD'"> ptos.</span></span>

                            </div>

                        </div>
                    </div>
                </div>
            </transition-group>
        </div>

    </div>

</template>

<script>

    import { state, actions } from '@/store';
    export default {
        props: ['loadingSl', 'sortBy', 'sortOrder'],
        components: {
        },
        data() {
            return {
                smu: null,
                loading: false,
                all:false
            }
        },
        computed: {
            params() { return state.globalParams },
            legendColors() { return state.slLegendColors },
            slSelectedItems() { return state.slSelectedItems },
            smuFiltered() {
                let arr = []
                if (this.smu) {
                    arr = this.smu.filter( item => {
                        return this.slSelectedItems.includes(item.id_sl)
                    })
                }
                return this._.orderBy(arr , [this.sortBy.value], [this.sortBy.direction])
            }
        },
        watch: {
            loadingSl() {
                if (!this.loadingSl) { this.getSmu() }
            }
        },
        methods: {
            getSmu() {
                this.smu = []
                this.loading = true
                this.axios.get('projects_management/ner_competency', {params: this.params})
                    .then(response => {
                        this.smu = response.data.data
                        this.loading = false
                    })
            },
            getOpacity(pill, k) {
                if (this.slSelectedItems.includes(k)) {
                    return 'color:' + pill.color + ';background-color:' + pill.color + '25;'
                }
                //return this.slSelectedItems.includes(k) ?  '25' : '12'
            },
            toggleSlSelectedItems(item) { actions.toggleSlSelectedItems(item) },
            removeCharacters(str) { return str.replace(/[^A-Z0-9]+/ig, "_") },
            toggleSlSelectedItemsAll(){

                this.all = !this.all

                if(state.slSelectedItems.length == 9 || !this.all){

                    actions.clearSlSelectedItems();

                } else {

                    for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
                        actions.addSlSelectedItems(Object.keys(this.legendColors)[index])
                    }

                }

                // for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
                //     actions.toggleSlSelectedItems(Object.keys(this.legendColors)[index])
                // }

            }
        }
    }
</script>